.loading {
  text-align: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  margin: auto;
  display: flex;
  position: relative;
}

.loading div {
  opacity: 1;
  border: 4px solid #fff;
  border-radius: 50%;
  width: 100%;
  margin-top: -10px;
  animation: 1s cubic-bezier(0, .2, .8, 1) infinite loading;
  position: absolute;
}

.loading div:nth-child(2) {
  animation-delay: -.5s;
}

@keyframes loading {
  0% {
    opacity: 1;
    width: 0;
    height: 0;
    top: 18px;
    left: 18px;
  }

  100% {
    opacity: 0;
    width: 36px;
    height: 36px;
    top: 0;
    left: 0;
  }
}

.loading.gray div {
  border: 4px solid #888;
}

.debug {
  position: fixed;
  bottom: 0;
}

.content.blank-page {
  overflow: overlay;
  margin-left: 0;
  transition: none;
  overflow-x: hidden;
  padding: 0 !important;
}

.bs-stepper-content {
  height: fit-content;
}

.alert {
  text-align: center;
  margin-top: 14px;
  padding: 16px;
}

.header-navbar {
  height: 60px;
}

.app-content, .app-ecommerce-details, .ecommerce-application {
  padding-top: 80px !important;
}

.horizontal-menu-wrapper {
  display: none;
}

body {
  overflow-y: scroll;
}

.header-navbar {
  top: 0;
}

.ecommerce-application .grid-view {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  justify-content: center;
}

img.logo {
  width: 100%;
  height: 50px;
}

.line {
  background: linear-gradient(to right, #d10107 28%, #001a46 20%);
  width: 100%;
  height: .7rem;
}

.bg-primary {
  background-color: #d10107;
}

.bg-landing {
  background-position: 50% 76px;
  background-repeat: no-repeat;
  background-size: cover;
  background-attachment: fixed;
  min-height: calc(100vh - 249px);
  position: relative;
}

.bg-blue {
  background: #001a46dd;
}

.card-in-landing-img {
  position: absolute;
  bottom: 60px;
}

.line-keyfacts {
  background-position: 0 0;
  background-repeat: no-repeat;
  background-size: contain;
  height: 79.5px;
  margin: 0 auto;
}

.bg-brown {
  background-color: #1e1e1e;
}

.format_head_det ul li:before {
  content: "";
  background-image: url("list_image_weiss.db519eff.svg");
  background-repeat: no-repeat;
  background-size: 10px;
  width: 10px;
  height: 10px;
  margin-right: 10px;
  display: inline-block;
}

.keyfacts {
  background-repeat: no-repeat;
  padding-bottom: 48px;
}

.line-keyfacts {
  background-repeat: no-repeat;
  height: 79.5px;
}

.keyfacts .standard {
  float: left;
  text-align: left;
  width: 373px;
  margin: 0;
  padding: 0;
  display: inline;
  position: relative;
  margin: 85px 40px 0 0 !important;
}

.keyfacts .standard:last-child {
  width: 346px;
  margin-right: 0 !important;
}

.red_bold {
  color: #d10107;
  font-size: 24px;
  font-weight: 700;
}

.nav-item {
  color: #000 !important;
}

.form-control:focus {
  border-color: #000;
}

.text-red {
  color: #d10107;
}

.update-offer-price {
  position: absolute;
  top: 20px;
  right: 20px;
}

.update-offer-price:hover {
  color: #d10107 !important;
}
/*# sourceMappingURL=index.b32ca0bd.css.map */
