

.loading {
  width:100%;
  text-align: center;
  display: inline-block;
  position: relative;
  width: 24px;
  height: 24px;
  margin:auto;
  display: flex;
  justify-content: center;
}
.loading div {
  width:100%;
  margin-top: -10px;
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: loading 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.loading div:nth-child(2) {
  animation-delay: -0.5s;
}
@keyframes loading {
  0% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 36px;
    height: 36px;
    opacity: 0;
  }
}

  
  

.loading.gray div {
  border: 4px solid #888
}

.debug {
  position: fixed;
  bottom: 0px;
}


.content.blank-page {
  overflow: overlay;
  overflow-x: hidden;
  padding: 0 !important;
  transition: none; 
  margin-left: 0; 
}

.bs-stepper-content {
  height: fit-content;
}


.alert {
  margin-top: 14px;
  padding: 16px;
  text-align: center;
}

.nav-item:not(:first-of-type) {
  // display: none;
}



.header-navbar {
  height: 60px;
}

.app-content, .app-ecommerce-details , .ecommerce-application {
  padding-top: 80px !important;
}

.horizontal-menu-wrapper {
  display: none;
}

body {
  overflow-y: scroll;
}

.header-navbar{
  top: 0px;
}

.ecommerce-application .grid-view {
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.ecommerce-application .grid-view .ecommerce-card .item-img {
  justify-content: center;
}

img.logo {
  width:100%;
  height:50px;
}


.line{
  height: 0.7rem;
  width: 100%;
  background: linear-gradient(to right , #d10107 28%, #001a46 20%);
}

.bg-primary {
  background-color: #d10107;
}

.bg-landing {
  background-size: cover;
  background-attachment: fixed;
  background-position: 50% 76px;
  background-repeat: no-repeat;
  position: relative;
  min-height: calc(100vh - 249px);
  // height: 545px;
}

.bg-blue {
  background: #001a46dd;
}

.card-in-landing-img {
  position: absolute;
    bottom: 60px;
}

.line-keyfacts {
  background-size: contain;
  background-position: left top;
  background-repeat: no-repeat;
  height: 79.5px;
  margin: 0 auto;
}

.bg-brown {
  background-color: #1e1e1e;
}

.format_head_det ul li:before {
  display: inline-block;
  height: 10px;
  width: 10px;
  content: '';
  background-size: 10px;
  background-image: url(./list_image_weiss.svg);
  background-repeat: no-repeat;
  margin-right: 10px;
}

.keyfacts{
  background-repeat: no-repeat;
  padding-bottom: 48px;
}

.line-keyfacts{
  background-repeat: no-repeat;
  height: 79.5px;
}

.keyfacts  .standard{
  width: 373px;
  margin: 85px 40px 0 0 !important;
  float: left;
  display: inline;
  position: relative;
  margin: 0px;
  padding: 0px;
  text-align: left;
}

.keyfacts .standard:last-child{
  margin-right: 0 !important;
  width: 346px;
}


.red_bold{
  color: #d10107;
  font-weight: 700;
  font-size: 24px;
}

.nav-item {
  color: #000 !important;
}

.form-control:focus {
  border-color: #000;
}

.text-red {
  color: #d10107;
}

.update-offer-price{
  position: absolute;
  right: 20px;
  top: 20px;
}

.update-offer-price:hover{
  color: #d10107 !important;
}